import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { alpha, styled } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";

import DashNavbar from "./DashNavbar";

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-input': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});




// patients page for them to them to select a option on how to process
// 3 btns for current features, user transactions history add later
// next: add in pdf viewer for user files 
export default function Patient(props) {
 /// var userData = props.history.location.state.userData;
  //console.log(userData)
  let history = useHistory();

  var userData = {
    "firstName": props.history.location.state? props.history.location.state.userData.firstname: "",
    "lastName": props.history.location.state? props.history.location.state.userData.lastname:"",
    "email": props.history.location.state? props.history.location.state.userData.email:"",
    "address": props.history.location.state? props.history.location.state.userData.address:"",
    "phonenumber": props.history.location.state? props.history.location.state.userData.phonenumber:"",
    "HealthCard": props.history.location.state? props.history.location.state.userData.HealthCard:""

  }
 
  let strData = window.sessionStorage.getItem('userData');
  let jsondata = JSON.parse(strData);

  if( !props.history.location.state ){
    userData = {
      "firstName": jsondata? jsondata.firstname: "",
      "lastName": jsondata? jsondata.lastname:"",
      "email": jsondata? jsondata.email:"",
      "address":jsondata? jsondata.address:"",
      "phonenumber": jsondata? jsondata.phonenumber:"",
      "HealthCard": jsondata? jsondata.HealthCard:""
  
    } 
  }

  var userUID = props.history.location.state ? props.history.location.state.uid : "";

  if( userUID == ""){
    userUID = window.sessionStorage.getItem('userUID'); 
  } 
  

  console.log(JSON.parse(strData)); 
  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "BlockSure" to = "/Patient" />
      <main>
        {/* 
          buttons for the various options along with some header text 
           bgcolor: '#f5f5f5',
        */}
        <sideMenu />
        <Box
          sx={{
           
            pt: 8,
            pb: 6
          }}
        >
          <Container  >
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Welcome {userData['firstName']},
            </Typography>
            <Grid container spacing={12}  padding= {3} >
              <Grid item md={6}>
                <img src="/logo.png" width="60%" height="60%" style={{padding: 1}} /> 
                {console.log(props)}
                <Stack
                sx={{ pt: 1 }}
                spacing={2}
                  justifyContent="center"
                >
                  <Button variant="contained">Account History</Button>
                  <Button 
                    variant="contained" 
                    onClick= {() =>  history.push("/Pending", { uid:userUID, userData:userData}) }
                  >Pending</Button>
                  <Button variant="contained"  
                     onClick= {() =>  history.push("/Records", { to: "/Patient",uid:userUID, userData:userData }) }

                  >Medical Record </Button>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h6" align="center" color="text.primary" paragraph>
                  Account Details 
                </Typography>
                <Grid container spacing={3} p>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      autoComplete="given-name"
                      name="firstName" 
                      borderColor="black"

                      id="firstName"
                      label="First Name" 
                      autoFocus 
                      value = {userData['firstName']} 
                      disabled={userData['firstName'] == ""}
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value= {userData['lastName']}
                      disabled={userData['lastName'] == ""}
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value= {userData['email']}
                      disabled={userData['email'] == ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      name="address"
                      label="Address"
                      type="address"
                      id="address"
                      value= {userData['address']}
                      disabled={userData['address'] == ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      name="phonenumber"
                      label="Phone Number"
                      type="phonenumber"
                      id="phonenumber"
                      value= {userData['phonenumber']  }
                      disabled={userData['phonenumber'] == ""}
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      name="HealthCard"
                      label="HealthCard" 
                      value= {userData['HealthCard']}
                      disabled={userData['HealthCard'] == ""} 
                       
                    />
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid> 

            
          </Container>
        </Box> 
      </main>
      
    </div>
  );
}

//direction="row"
/*
 
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Keep up to date with your medical history 
                </Typography>
*/