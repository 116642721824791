import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import DashNavbar from "./DashNavbar";
import { useHistory } from "react-router-dom";


const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const theme = createTheme();

// doctor/ clinic sign will show option on how to view patient files, add patients and add files 
// current just 3 buttons for user features
// add in account transactions history feature
export default function DoctorSide(props) {
  //console.log(props.history.location.state.userData)
  let history = useHistory();
  var userData = {
    "clinicname": props.history.location.state? props.history.location.state.userData.clinicname:"",
    "email": props.history.location.state? props.history.location.state.userData.email:"",
    "address": props.history.location.state? props.history.location.state.userData.address:"",
    "phonenumber": props.history.location.state? props.history.location.state.userData.phonenumber:"",
    "type": props.history.location.state? props.history.location.state.userData.type:""
  }

  let strData = window.sessionStorage.getItem('userData');
  let jsondata = JSON.parse(strData);

  if( !props.history.location.state ){
    userData = {
      "clinicname": jsondata? jsondata.clinicname: "", 
      "email": jsondata? jsondata.email:"",
      "address":jsondata? jsondata.address:"",
      "phonenumber": jsondata? jsondata.phonenumber:"",
      "type": jsondata? jsondata.type:""
  
    } 
  }

  var userUID = props.history.location.state ? props.history.location.state.uid : "";

  if( userUID == ""){
    userUID = window.sessionStorage.getItem('userUID'); 
  } 


  return (
    <div>
      <CssBaseline />
 
      <DashNavbar title = "BlockSure" to = "/Doctor" />
      <main>
        {/* Hero unit 
            bgcolor: 'background.paper',
        */}
        <Box
          sx={{
            pt: 8,
            pb: 6,
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <Container alignItems="center" justifyContent="center">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              
            >
              Welcome {userData['clinicname']},
            </Typography>
            <Grid container spacing={12}  alignItems="center" justifyContent="center" padding= {3} >
              <Grid item md={6}>
                <img src="/logo.png" width="60%" height="60%" style={{padding: 1}} /> 
                {console.log(props)}
                <Stack
                  sx={{ pt:1 }} 
                  spacing={2}
                  justifyContent="center"
                >
                  <Button variant="contained">Account History</Button>
                  <Button variant="contained"  
                    onClick= {() =>  history.push("/DoctorUpload", { userData:userData, uid: userUID }) }
                  > 
                    New Document 
                  </Button>
                  <Button variant="contained"
                    onClick= {() =>  
                      history.push("/PatientsList", { userData:userData, uid: userUID }) 
                    }
                  > 
                    View Patients 
                  </Button>
                </Stack>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h6" align="center" color="text.secondary" paragraph>
                  Account Details 
                </Typography>
                <Grid container spacing={4} p>
                  <Grid item xs={12}>
                    <TextField
                      name="clinicName"
                      
                      fullWidth
                      id="clinicName"
                      label="Clinic Name"
                      autoFocus 
                      value= {userData['clinicname']} 
                    />
                  </Grid> 
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value= {userData['email']}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      name="address"
                      label="Address"
                      type="address"
                      id="address"
                      value= {userData['address']} 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField 
                      fullWidth
                      name="phonenumber"
                      label="Phone Number"
                      type="phonenumber"
                      id="phonenumber"
                      value= {userData['phonenumber']} 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="Type"
                      label="Type of Clinic" 
                      id="Type" 
                      value= {userData['type']} 
                    />
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid> 
          </Container>
        </Box> 
      </main>
       
    </div>
  );
}

/*
<AppBar position="relative">
        <Toolbar>
          <PersonIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Doctor Page 
          </Typography>
        </Toolbar>
      </AppBar>
*/

/*
<Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              BlockSure
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Keep up to date with your medical history 
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained">Account History</Button>
              <Button variant="contained" 
              href="/DoctorUpload"
              onClick= {() =>  history.push("/DoctorUpload", { userData:userData, uid: props.history.location.state ? props.history.location.state.uid : "" }) }
              > 
              New Document 
              </Button>
              <Button variant="contained"
                      onClick= {() =>  history.push("/PatientsList", { userData:userData, uid: props.history.location.state ? props.history.location.state.uid : "" }) }

              > 
              View Patients 
              </Button>
            </Stack>
          </Container>
*/