import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect, useContext, createContext } from 'react'

import Navbar from "../layout/Navbar";

import { useCallback } from 'react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'

import { useHistory } from "react-router-dom";
import {writeUserData} from "../../fireBase";
import APIpath from "../apiPath";
const axios = require('axios');

const theme = createTheme();

// user sign up using a material ui form
export default function SignUp() {
  let history = useHistory();
  const [pass,setpass]=useState(true);
  // function for when the form is submitted
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const auth = getAuth();
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    // add mediation here to set pass
    try {
      createUserWithEmailAndPassword(auth, data.get('email'), data.get('password')).then( 
        (uuid) => {
          // console.log(data)
          console.log(uuid.user.uid);
          writeUserData(uuid.user.uid,  data.get('firstName'), data.get('lastName'),  data.get('email'),  data.get('address'), data.get('phonenumber'), "Patient", data.get('HealthCard'));
          axios.post(APIpath+'NewUser', {uid : uuid.user.uid})
          history.push("/Login");
        }
      )
    } catch (e) {

      alert(e.message);
      setpass(false);
    }

  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            User Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label="Address"
                  type="address"
                  id="address"
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phonenumber"
                  label="Phone Number"
                  type="phonenumber"
                  id="phonenumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="HealthCard"
                  label="HealthCard"  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained" 
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            { !pass && 
              <Typography component="h6" variant="h6" style={{ color: 'red' }}>
                email or password not good enough?
              </Typography>
            }
            <Grid container justifyContent="flex-center">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box> 
      </Container>
    </ThemeProvider>
  );
}