// add in oauth routes : https://dev.to/jsbroks/firebase-authentication-with-react-and-guarded-routes-41nm 
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { useState, useEffect, useContext, createContext } from 'react';
import { getDatabase, ref, set, child, get, orderByChild,equalTo,onValue,query  } from "firebase/database";


const firebaseConfig = {
  apiKey: "AIzaSyDsBI1doCE5kQWbTuaxqRldynfJiFRxD3g",
  authDomain: "blocksure-8c41f.firebaseapp.com",
  projectId: "blocksure-8c41f",
  storageBucket: "blocksure-8c41f.appspot.com",
  messagingSenderId: "181335631468",
  appId: "1:181335631468:web:b24d607418461d3f8dda49",
  measurementId: "G-QBWN5J0J2Z"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);
export const AuthContext = createContext();

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
};

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
};

export function writeUserData(userId, firstname, lastname, email, address,phonenumber,role,HealthCard) { 
  set(ref(db, 'users/' + userId), {
    firstname: firstname,
    lastname: lastname,
    email: email,
    address : address,
    phonenumber : phonenumber,
    HealthCard : HealthCard,
    role : role
  });
};
 
export function writeClinicUserData(userId, clinicname, email, address,phonenumber,role,type) { 
  set(ref(db, 'users/' + userId), {
    clinicname: clinicname,
    email: email,
    address: address,
    phonenumber : phonenumber,
    role : role,
    type : type 
  });
};

export function getUserDataSignIn(userId) { 

  get(child(db, `users/${userId}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val()); 
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  })
};

export function UserSearch(HealthCard) { 
  // fix path
  console.log("here")
  /*
  get(child(db, `users/{"$id"}/${HealthCard}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  })
  */
  onValue(query(ref(db, 'users/'), orderByChild('HealthCard'),equalTo(HealthCard) ), snapshot => {
    console.log(snapshot.val())
  })
};

export function patientSearch(setclinicPatients) { 
  // fix path
  console.log("here")
  /*
  get(child(db, `users/{"$id"}/${HealthCard}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  })
  */
  onValue(query(ref(db, 'users/'), orderByChild('role'),equalTo("Patient") ), snapshot => {
    console.log(snapshot.val());
    var arrData = [];
    const patients = snapshot.val();
    var datakeys = Object.keys(patients);
    datakeys.forEach(function(key){
      //console.log( patients[key] )
      //console.log( key )
      const patient = patients[key]; 
      //const name = patients[key]['firstname'] + " " + patients[key]['lastname'] 
      //console.log(patient['HealthCard']);
      //"HealthCard": patient['HealthCard'], 'PatientName':name, 'PhoneNumber': patients[key]['phonenumber']
      //patient['PatientName'] = name;

      arrData.push((patient))
    });
    console.log(arrData);
    setclinicPatients(arrData)
  })
};

export function writeReport(healthCard, clinicName,clinicEmail, clinicAddress,reportType, ReportDetails,reportPath,time) { 
  console.log('firebase write report ');
  console.log(healthCard);
  console.log(clinicName);
  onValue(query(ref(db, 'users/'), orderByChild('HealthCard'),equalTo(healthCard) ), snapshot => {
    
    const data = snapshot.val(); 
    console.log("inside");
    console.log(data);
    if( data != null){
      console.log(data);
      const uid = Object.keys(data);
      //const currentTime = Date.now();
      console.log(uid);
      console.log(uid[0]);
      //append list, add file
      if( reportType !== "" && ReportDetails !== ""){
        set(ref(db, 'users/' + uid[0] + "/Requests/" +  time), {
          clinicName: clinicName,
          clinicEmail:clinicEmail,
          clinicAddress:clinicAddress, 
          reportType: reportType,
          ReportDetails: ReportDetails,
          reportPath : reportPath
        });
      }else{
        console.log('empty values')
      }
    }else{
      console.log('user not found ') // add pop/alert 
    }
    
    
  })
  
};

export function DeleteReport(uid, requestId) { 


  set(ref(db, 'users/' + uid + "/Requests/" +  requestId), { }).then( () =>
    console.log('deleted record ')
  ).catch(() =>{
    console.log('error with deleting record ')
  });
  
};

export function GetRequests(uid) { 


  
  const requests = ref(db, 'users/' + "E1Ity4hqHOhiBVt3k4lbfkxvIJz1" +"/Requests");
    onValue(requests, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      //setrequests([data]); 
      
    }); 
      
  
};

// pass to blockchain api 
/*
export function ApproveRequests(uuid) { 
  
  get(child(db, `users/${uuid}/Requests`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val()); 
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  })
  
};

export function deleteReport(healthCard, reportType, ReportDetails,time) { 
  
  onValue(query(ref(db, 'users/'), orderByChild('HealthCard'),equalTo(healthCard) ), snapshot => {
    
    const data = snapshot.val();
    
    console.log(data);
    const uid = Object.keys(data);
    //const currentTime = Date.now();
    console.log(uid  );
    console.log(uid[0]  );
    //append list, add file
    if( reportType !== "" && ReportDetails !== ""){
      set(ref(db, 'users/' + uid[0] + "/Requests/" +  time), {
        reportType: reportType,
        ReportDetails: ReportDetails
      });
    }else{
      console.log('empty values')
    }
    
  })
  
};
*/
/*
export function UserRequest(HealthCard) { 
  // fix path
  set(ref(db, 'users/' + userId), {
    clinicname: clinicname,
    email: email,
    address: address,
    phonenumber : phonenumber,
    role : role,
    type : type 
  });
};
*/
