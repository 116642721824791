import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import Navbar from "./Navbar";

import upload from "../uploadFile"
import CreateDoc from "../CreateDoc"

function UploadDocument(props) {
  //props.userData.clinicname , props.userData.email, props.userData.address,
  const userData = {
    "clinicname":props.history.location.state.clinicname,
    "email":  props.history.location.state.email,
    "address": props.history.location.state.address
  };
  return (
    <React.Fragment>
      {console.log(props)}
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      <div>
        <Navbar />
      </div>
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          New Patient Document 
        </Typography>  
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
         <h1>Create Patient Document</h1>
         <CreateDoc userData= {userData} to = {"/"} /> 
      </Container> 

    </React.Fragment>
  );
}

export default UploadDocument;