import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import Navbar from "./Navbar"; // toolbar for pages outside of signin 
import Footer from "./footer"; // footer component


// options for the landing page, to display the cards
const options = [
  {
    title: 'Documents', 
    description: [
      'Upload Documents for patients to access documents easily and Clinic Sign Up',  
    ],
    buttonText: 'Upload',
    buttonVariant: 'contained',
    to:"/Clinic",
    color :"#3D52D5"
  },
  {
    title: 'Sign Up ',  
    description: [
      'User Sign up so you can access your medical records quickly and easily, for only $50 a year', 
      ""
    ],
    buttonText: 'Sign Up',
    buttonVariant: 'contained',
    to:"/Register",
    color :"#3D52D5"
  }, 
  {
    title: 'Log In ', 
    description: [
      'Sign In and view your medical history or grant access to doctors and clinics ',  
    ],
    buttonText: 'Log In',
    buttonVariant: 'contained',
    to:"/Login",
    color :"#3D52D5"

  },
];


const styles = {
   Container: {
      backgroundImage: `url(pexels-pixabay-40568.jpg)`
  }
};


// main page where user starts off at, to sign in, up or to upload documents 
// contains 3 buttons and information
// add images to improve feel   
//backgroundImage:`url( pexels-pixabay-40568.jpg )` ,
function LandingPage() {
  return (
    
    <div  style ={{
      height:"100%", 
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage:`linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.6)),url( pexels-pixabay-40568.jpg )`  
      
    }} > 
      
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      <div>
        <Navbar />
      </div>
      <div>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 10, pb: 20 }} >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Welcome to BlockSure 
        </Typography>
        
        
        <Typography variant="h5" align="center" color="text.secondary" component="p" sx={{ my: 1, mx: 1.5 }}>
          An EHR software that is dedicated to giving users access to their own medical records along with reducing time being wasted on transporting medical documents making it so
          doctors can spend more time on doing what matters. 
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {options.map((option) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={option.title}
              xs={12}
              sm={option.title === 'Enterprise' ? 12 : 6}
              md={4}
              padding={5}
            >
              <Card>
                <CardHeader
                  title={option.title}
                  subheader={option.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={option.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  
                  <ul>
                    {option.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth href={option.to} variant={option.buttonVariant} style = {{backgroundColor:option.color}} >
                    {option.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </div> 
    </div>
  );
}

export default function Landing() {
  return <LandingPage />;
}