
import './App.css';

import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";

import { Provider } from "react-redux";
//import store from "./store";


import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import ClinicRegister from "./components/auth/ClinicRegister";
import Login from "./components/auth/Login"; 
import Patient from "./components/dashboard/Patient"; 
import DoctorSide from "./components/dashboard/DoctorSide"; 
import UploadDocument from "./components/layout/UploadDocument"; 
import UploadDocDocument from "./components/dashboard/UploadDocDocument"; 
import clinicInfo from "./components/layout/ClinicInfo";
import Pending from "./components/dashboard/Pending";
import Records from "./components/dashboard/MedicalRecords";
import PatientsList from "./components/dashboard/PatientsList";

import RecordDetails from "./components/dashboard/recordDetails";
// landing page under layout is main page, the rest connect from there
// add some security to the paths separated since they should be accessible only after signing in
// todos:
// add in selects for forms
// main comp missing right now is for the account history pages 
// connect to backend when done 
// add in proper sign in + oauth, jwt decode might be necessary 
// add images on home pages so it appears better

function App() {
  return (
    <div className="App">
     
          <Router>
            <div >
              
              <Route exact path="/" component={Landing} />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/Register" component={Register} /> 
              <Route exact path="/ClinicRegister" component={ClinicRegister} />
              <Route exact path="/Upload" component={UploadDocument} /> 
              <Route exact path="/DoctorUpload" component={UploadDocDocument} /> 

              
              <Route exact path="/Patient" component={Patient}   /> 
              <Route exact path="/Doctor" component={DoctorSide} /> 
              <Route exact path="/Clinic" component={clinicInfo} />
              <Route exact path="/Pending" component={Pending} /> 
              <Route exact path="/Records" component={Records} /> 
              <Route exact path="/PatientsList" component={PatientsList} /> 

              <Route exact path="/RecordDetails" component={RecordDetails} /> 

            </div>
          </Router>
    </div>
  );
}

export default App;
