import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import DashNavbar from "./DashNavbar";
import RequestForm from "./RequestForm";
import { useState, useEffect  } from 'react'

import { getDatabase, ref, set, child, get, orderByChild,equalTo,onValue,query  } from "firebase/database";

import { db,getUserDataSignIn,getRequests, ApproveRequests,deleteReport } from '../../fireBase';

// component for pending requests for the current patient
// next steps
// add in gallary to scroll through and pass the data to the child component 
export default function Pending(props) {
  // dummy data for pending reports, schema will be the same
  const [currentIndex, setindex] = useState(0);
  const [requests, setrequests] = useState([]);
  const [keys, setkeys] = useState([]);
  const emptyReport = {
     "clinicName" : "",
      "clinicEmail" : "",
      "clinicAddress" : "",
      "reportType": "",
      "ReportDetails": "",
      "PhoneNumber": "",
      'uid' : ""
  };

  const reports = [
    { "clinic" : "The MedicalClinic",
      "email" : "clinic@medical.com",
      "address" : "some st",
      "reportType": "Blood",
      "urgency": "low",
      "PhoneNumber": "123-123-1234"
      ,
    "ReportDetails":"report done "
    },{ "clinic" : "Dr.Patrick",
    "email" : "Dr_Star@medical.com",
    "address" : "thing st",
    "reportType": "access request",
    "urgency": "low",
    "PhoneNumber": "123-321-1234",
    "ReportDetails":"access request"
  }
  ];

  // update method to be appropriate later
  console.log(props);
  const uid = props.history.location.state.uid;
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   
    console.log('get');
    //getUserDataSignIn("E1Ity4hqHOhiBVt3k4lbfkxvIJz1")
    setrequests([]);
    const starCountRef = ref(db, 'users/' + uid +"/Requests");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log(typeof(data))
      if((data) != null){
        //setrequests([data]);
        var arrData = [];
        setkeys(Object.keys(data));
        console.log(keys);
        var datakeys = Object.keys(data);
        datakeys.forEach(function(key){
          
          arrData.push((data[key]))
        });
        setrequests( arrData );
      }      
    }); 
  }, []);
  
  
  const handleSubmit = (event) => { // repurpose, but called when form is submitted to add the data to the blockchain 
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "BlockSure" to = "/Patient" />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Pending Approval  ({requests.length != 0 ? currentIndex+1: 0} / {requests.length})
            {console.log(requests)}
          </Typography>

          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button 
              variant="contained" 
              startIcon={<ArrowBackIosIcon />} 
              onClick= {() => (currentIndex == 0) ? setindex(requests.length -1) :  setindex(currentIndex - 1)}

              disabled = {requests.length != 0 ? false : true }
              >
              back
              </Button>
            </Grid>
            {console.log(keys)}
            <Grid item xs={12} sm={6}> 
              <Button 
              variant="contained" 
              endIcon={<ArrowForwardIosIcon />} 
              onClick={() => (currentIndex >= (requests.length -1)) ? setindex(0) :  setindex(currentIndex + 1)}
              disabled = {requests.length != 0 ? false : true }
              >
              next 
              </Button> 
            </Grid>
          </Grid> 
          {console.log(props)}
          <span key={requests.length}>{
            <span key={currentIndex}>{
              <RequestForm report = {requests[currentIndex] ? requests[currentIndex]:emptyReport} reportID = {keys[currentIndex] ? keys[currentIndex]:0} uid = {props.history.location.state.uid} userData = {props.history.location.state.userData} currentLength = {requests.length} /> 
            }</span>
        }</span>
          
        </Box>
        
      </Container>
      <br />
 
    </div>
  );
}

/*
{reports.map((row) => 
            <RequestForm report = {row} />
            
          )}
*/