// add in oauth routes : https://dev.to/jsbroks/firebase-authentication-with-react-and-guarded-routes-41nm 
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { useState, useEffect, useContext, createContext } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL   } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDsBI1doCE5kQWbTuaxqRldynfJiFRxD3g",
  authDomain: "blocksure-8c41f.firebaseapp.com",
  projectId: "blocksure-8c41f",
  storageBucket: "blocksure-8c41f.appspot.com",
  messagingSenderId: "181335631468",
  appId: "1:181335631468:web:b24d607418461d3f8dda49",
  measurementId: "G-QBWN5J0J2Z"
};

export const firebaseApp = initializeApp(firebaseConfig);
 
// Create a root reference
export const storage = getStorage(); 
//export const db = getDatabase(firebaseApp);

export function UploadFile(file,dt) { 
  const storageRef = ref(storage, `files/${file.name}`);
  
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('Uploaded a blob or file!');
  });

  //storage.ref(`/files/${file.name}`).put(file)
  //  .on("state_changed" , alert("success") , alert);
  
};
 
 

export function downloadFile(filePath,setpdfurl) { 
  getDownloadURL(ref(storage, filePath))
  .then((url) => {
    // `url` is the download URL for 'images/stars.jpg'
    console.log(url);
    setpdfurl(url) ;
    // This can be downloaded directly:
    //const xhr = new XMLHttpRequest();
    //xhr.responseType = 'blob';
    //xhr.onload = (event) => {
    //  const blob = xhr.response;
    //};
    //xhr.open('GET', url);
    //xhr.send();

    
  })
  .catch((error) => {
    // Handle any errors
  });
};