import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import DashNavbar from "./DashNavbar";

import upload from "../uploadFile"
import CreateDoc from "../CreateDoc"

function UploadDocument(props) {
  return (
    <React.Fragment>
      
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
        <DashNavbar title = "BlockSure" to = "/Doctor" />
       
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Patient Document 
        </Typography>  
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
         <h1>Create Patient Document</h1>
         <CreateDoc userData = {props.history.location.state.userData} to = {"/Doctor"}/>
         <upload/>
      </Container> 

    </React.Fragment>
  );
}

export default UploadDocument;