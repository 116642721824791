import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from "react-router-dom";
import {useState} from 'react';

import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {writeReport} from "../fireBase"
import {UploadFile} from "../fireBaseStorage"

const theme = createTheme();

export default function ClinicForm(props) {
  let history = useHistory();
  console.log(props)
  

  const [file , setfile] = useState('');

  const upload = ()=>{
    if(file == null)
      return;
    //storage.ref(`/files/${file.name}`).put(file)
    //.on("state_changed" , alert("success") , alert);
    const dt =  Date.now();
    console.log(file);
    UploadFile(file,dt);

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console 
    console.log(props)
    
    //props.history.location.state.userData.clinicname , props.history.location.state.userData.email, props.history.location.state.userData.address, props.history.location.state.userData.phonenumber, props.history.location.state.userData.type
    //healthCard, clinicName,clinicEmail, clinicAddress,urgency,reportType, ReportDetails,time
    console.log('write report ')
    writeReport(data.get('HealthCard'),props.userData.clinicname , props.userData.email, props.userData.address, data.get('ReportType'),data.get('Details'), `files/${file.name}` ,Date.now());
    upload();
    history.push(props.to);
  };

  return (
    <ThemeProvider theme={theme}>
 
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Fill information 
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="HealthCard"
                  label="Health Card"  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="ReportType"
                  label="Report Type"  
                />
                
              </Grid>               
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  name="Details"
                  label="ReportDetails"  

                />
              </Grid>
              <Grid item xs={12} >
              <Button
                variant="contained"
                component="label"
                name="file"
              >
                Upload File
              <input
                type="file"
                onChange={(e)=>{setfile(e.target.files[0])}}
                hidden
              />
              </Button>

              <p>{file.name}</p>
              
              </Grid> 
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
            >
              Submit
            </Button> 
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}


//<input type="file" onChange={(e)=>{setfile(e.target.files[0])}}/>
//<button onClick={upload}>Upload</button>