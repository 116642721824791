import * as React from 'react';
import { useState, useEffect, useContext, createContext } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, useHistory } from "react-router-dom";

import { useCallback } from 'react'

import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { ref, onValue } from "firebase/database";

import Navbar from "../layout/Navbar";
import {db} from "../../fireBase"

const theme = createTheme(); 
//registration for users
// after blockchain signup works remove the extra buttons for navigating directly; or add dummy accounts for now
export default function SignIn() {
  let history = useHistory();
  const auth = getAuth()

  const [email, setemail] = useState("");

  // function when submit is pressed
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    try {
      signInWithEmailAndPassword(auth, data.get('email'), data.get('password')).then(
        (uuid) => {
          // console.log(data)
          console.log(uuid.user.uid);
          // writeUserData(uuid.user.uid,  data.get('firstName'), data.get('lastName'),  data.get('email'),  data.get('address'), data.get('phonenumber'), "Patient", data.get('HealthCard'));
          //const userData = getUserDataSignIn(uuid.user.uid) 
            const starCountRef = ref(db, 'users/' + uuid.user.uid );
            onValue(starCountRef, (snapshot) => {
              const data = snapshot.val();
              console.log(data)
              window.sessionStorage.setItem('userData', JSON.stringify(data));
              window.sessionStorage.setItem('userUID', (uuid.user.uid));
              if(data.role === "Clinic"){ 
                history.push("/Doctor", { userData: data , uid: uuid.user.uid});
              }else{
                history.push("/Patient", { userData: data , uid: uuid.user.uid});
              }
            });

        
        }
      )
    } catch (e) {
      alert(e.message)
    }
  };
 
  const handleForgetPass = () => { 
    console.log(email)
    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent! 
      // ..
      console.log('send pass reset to email ')
      history.push("/");

    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
  }

  

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Navbar />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"

              value={email}
              onChange={(p) => {setemail(p.target.value)} } 
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button> 

            <Grid container>
              <Grid item xs> 
                <Button  onClick={() => {

                  handleForgetPass()
                }}>
                  Forgot password?
                </Button>
              </Grid>
              <Grid item >
                <Button href="/Register"  >
                  {"Don't have an account? Sign Up"}
                </Button> 
              </Grid>
            </Grid>
            
          </Box>
        </Box> 

        
        
      </Container>
    </ThemeProvider>
  );
}
/*
<Link to="/Patient">
          <Button 
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
          >
              Sign In Pat 
          </Button>
        </Link>
          <Link to="/Doctor">
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In Doctor 
              
            </Button>
            </Link>
*/