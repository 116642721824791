import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';



import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';
 
 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import DashNavbar from "./DashNavbar";

import {getUserDataSignIn, clinicSearch,db} from "../../fireBase"; 
import { ref, onValue, query, orderByChild,equalTo } from "firebase/database";

import { useHistory } from "react-router-dom";
import { useEffect  } from 'react';

import { patientSearch } from '../../fireBase';

// list of patients
// next connect it to the medical records using the navigate function
export default function PatientList(props) {
  
  // table for columns
  const columns = [
    { id: 'firstname', label: 'First Name', minWidth: 230 },
    { id: 'lastname', label: 'Last Name', minWidth: 230 },
    { id: 'HealthCard', label: 'Health Card', minWidth: 200 },
    { id: 'phonenumber', label: 'Phone Number', minWidth: 150 }
     
  ];

  let history = useHistory();


  // reports dummy data, this might change, but hyperledger can hold pdfs, so depending on taht te table clicked will go to another page that shows a pdf or a form accordingly 
  // id should be healthcard number or something 
  // on medical records page for doctor add pass through for creating 
  const patients = [
    { id:0,
      "PatientName" : "Sandy", 
      "PhoneNumber" : "123-121-1233", 
      "LastUpdate"  : "2021-9-02" 

    },{id:1,
       "PatientName" : "Plankton", 
       "PhoneNumber" : "123-311-1233",  
       "LastUpdate"  : "2021-10-20" 
      },{id:2,
        "PatientName" : "Mr.Krabs", 
        "PhoneNumber" : "123-321-1233",  
        "LastUpdate"  : "2019-10-15"  
      } 
  ];
 
  // state variables for teh table to display 
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [clinicPatients, setclinicPatients] = React.useState([]);

  var userData = {
    "clinicname": props.history.location.state? props.history.location.state.userData.clinicname:"",
    "email": props.history.location.state? props.history.location.state.userData.email:"",
    "address": props.history.location.state? props.history.location.state.userData.address:"",
    "phonenumber": props.history.location.state? props.history.location.state.userData.phonenumber:"",
    "type": props.history.location.state? props.history.location.state.userData.type:""
  }

  let strData = window.sessionStorage.getItem('userData');
  let jsondata = JSON.parse(strData);

  if( !props.history.location.state ){
    userData = {
      "clinicname": jsondata? jsondata.clinicname: "", 
      "email": jsondata? jsondata.email:"",
      "address":jsondata? jsondata.address:"",
      "phonenumber": jsondata? jsondata.phonenumber:"",
      "type": jsondata? jsondata.type:""
  
    } 
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const RowSelected = (row) =>{ // function to go to the next page and pass the user data
    // row click is passed as props
    console.log(row);
    // navigate to selected patient page 
    history.push("/Records",{ to: "/Doctor",uid: props.history.location.state ? props.history.location.state.uid : "",userData:row } )

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   
    console.log('get patients ');
    try {
      patientSearch(setclinicPatients);
      console.log(clinicPatients);


    } catch (error) {
      console.log('no patients'); 
    } 
     
  }, []);

  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "BlockSure" to = "/Doctor" />
      <Container component="main"  >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Patients List
          </Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clinicPatients
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={() => RowSelected(row) } >
                      {columns.map((column) => {
                        
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} >
                            { value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={(patients.length/rowsPerPage) < 1 ? 1 : (patients.length/rowsPerPage)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
 
      <Button  
        onClick= {() =>  history.push("/DoctorUpload", { userData: userData, uid: props.history.location.state ? props.history.location.state.uid : "" }) }
        variant="contained" sx={{ my: 2, mx: 1.5 }}
      >
          Add new File or Request Access to Patient
      </Button>

    </Box>
    </Container>
    </div>
  );
}

