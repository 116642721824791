
import React, { Component } from "react";
import { Document, Page,pdfjs } from "react-pdf";
import { useState, useEffect  } from 'react';
import {downloadFile} from "../../fireBaseStorage";

import CssBaseline from '@mui/material/CssBaseline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Grid from '@mui/material/Grid'; 
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';


import sample from "./sample.pdf";
//https://pspdfkit.com/blog/2018/open-pdf-in-react/
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
 
export default function PdfDisplay(props) {

  const [pageNumber, setpageNumber] = useState(1);
  const [numPages, setnumPages] = useState(0); 

  const [pdfurl, setpdfurl] = useState(''); 

  function onDocumentLoadSuccess({ numPages }) {
    setnumPages(numPages);
  }

  useEffect(() => {
   
    console.log('get file ');
    //downloadFile("/Medical Report Form Sample.pdf",setpdfurl);
    downloadFile(props.reportdetails.reportPath,setpdfurl);
    console.log(pdfurl)
    console.log(props)
    //https://firebasestorage.googleapis.com/v0/b/blocksure-8c41f.appspot.com/o/Medical%20Report%20Form%20Sample.pdf?alt=media&token=11649600-6654-4557-950e-505f1b06b471
    
    
  }, []);

  return (
    <div>
      <nav> 

        <Grid container spacing={2}>
            <Grid item xs={9} sm={4}>
              <Button 
              variant="contained" 
              startIcon={<ArrowBackIosIcon />} 
              onClick= {() =>  (pageNumber == 1) ? setpageNumber(numPages) :  setpageNumber(pageNumber - 1)}
              disabled = {numPages != 0 ? false : true }
              >
              back
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography component="h4" variant="h6">
                Page {pageNumber} of {numPages}
              </Typography>
                
            </Grid>
            <Grid item xs={9} sm={4}> 
              <Button 
              variant="contained" 
              endIcon={<ArrowForwardIosIcon />} 
              onClick={() => (pageNumber >= (numPages -1)) ? setpageNumber(1) :  setpageNumber(pageNumber + 1)}
              disabled = {numPages != 0 ? false : true }
              >
              next 
              </Button> 
            </Grid>
        </Grid> 
      </nav>

      <div style={{ width: 800 }}>
        {
          (pdfurl != '') &&
            <Document
            file={pdfurl }
            onLoadSuccess={onDocumentLoadSuccess}
            >
            <Page pageNumber={pageNumber} width={600} />
            </Document>
          
        }
        
      </div>

     
 
    </div>
  );
}

