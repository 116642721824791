import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';
 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { useHistory } from "react-router-dom";
import { useState, useEffect  } from 'react';
import APIpath from "../apiPath";
import DashNavbar from "./DashNavbar";
import axios from 'axios';

import { getAuth } from 'firebase/auth'


// patient medical records
// make copy for doctor side , check more data needed 
// look into click and display pdf -- either store with it or have anther location for files 
export default function MedicalRecords(props) {
  let history = useHistory();
  const [userReports, setuserReports] = useState([]);
  // table for columns
  const columns = [
    { id: 'clinicName', label: 'Clinic Name', minWidth: 170 },
    { id: 'reportName', label: 'Report Name', minWidth: 170 },
    { id: 'reportType', label: 'Report Type', minWidth: 100 }
  ];

  const authValues = getAuth(); 
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
   
    console.log('get reports ');
    if(props.history.location.state && props.history.location.state.HealthCard != ""){
      console.log(props.history.location.state.userData.HealthCard)
      const userUID = (props.history.location.state.uid != "") ? props.history.location.state.uid : authValues.lastNotifiedUid;
      try {
      const result = axios.post(APIpath+"Healthquery/", {"uid" :userUID , "healthCard":props.history.location.state.userData.HealthCard}).then(
        function(data){
          console.log(data);
          if((data.data) != null){
            //setrequests([data]);
            console.log('pulldata');
            const values = data.data.response;
            console.log(values)
            setuserReports(values);
            //values.forEach(function(key){
            //setuserReports([...userReports, (key.Record) ]);
            //});
          }
        }
      );
      // 
        
      } catch (error) {
        console.log('no data on block chain for user');
        
        
      }
        
    }
    
    
  }, []);

  // reports dummy data, this might change, but hyperledger can hold pdfs, so depending on taht te table clicked will go to another page that shows a pdf or a form accordingly 
  const reports = [
    { id:0,
      "clinicName" : "The MedicalClinic", 
      "reportType" : "xray", 
      "reportName" : "left knee "
    },{id:1,
       "clinicName" : "The apple tree clinic", 
      "reportType" : "blood test", 
      "reportName" : "yearly test"  
      },{id:2,
         "clinicName" : "dr.patrick", 
      "reportType" : "checkup", 
      "reportName" : "yearly checkup"
      } 
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const RowSelected = (row) =>{ // function to go to the next page and pass the user data
    // row click is passed as props
    console.log(row);
    // push the records details  
    history.push("/RecordDetails" , {"reportdetails" : row['Record'], "to":props.history.location.state.to })

  }

  return (
    <div>
      <CssBaseline />
      <DashNavbar title = "BlockSure" to = {props.history.location.state ? props.history.location.state.to :"/Patient"} />
      <Container component="main"  >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Patient History
          </Typography>
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 540 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userReports
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}  onClick={() => RowSelected(row) }>
                    {columns.map((column) => {
                      console.log(row);
                      console.log(column);
                      
                      var value = row.Record[column.id]; 
                      return (
                        <TableCell key={column.id} align={column.align}>
                          { value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={(reports.length/rowsPerPage) < 1 ? 1 : (reports.length/rowsPerPage)}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </Box>
    </Container>
    </div>
  );
}

