import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';

import { useHistory } from "react-router-dom";
import {DeleteReport} from "../../fireBase";
import APIpath from "../apiPath";
const axios = require('axios');
// component for the form so multiple can be generated
// next step 
// add in the handle button and pass in the user id, update color and take data from parent component
export default function RequestForm(props) {
  let history = useHistory();
  const report = props.report;
  /*
    { "clinic" : "The MedicalClinic",
      "email" : "clinic@medical.com",
      "address" : "some st",
      "reportType": "Blood",
      "urgency": "low",
      "PhoneNumber": "123-123-1234"
    };
    */

  const handleSubmit = (event) => { // repurpose, but called when form is submitted to add the data to the blockchain 
    event.preventDefault();
    console.log("handleSubmit"); 
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    //href="/Patient"
    console.log(props)
    if(props.reportID != 0 ){ 
      console.log(props.reportID);
      console.log(props.uid);
      const newRecord = { 
        "healthCard":props.userData.HealthCard, 
        "clinicName":props.report.clinicName, 
        "firstName":props.userData.firstName, 
        "lastName":props.userData.firstName,
        "reportType":props.report.reportType,
        "reportDetails":props.report.ReportDetails,
        "clinicAddress":props.report.clinicAddress, 
        "reportPath":props.report.reportPath,
        "uid":props.uid
      };
      axios.post(APIpath+'record', newRecord).then(
        (data) => {
          console.log(data);
          if(data.status == 200){
            console.log('success');
            DeleteReport(props.uid, props.reportID);
            
            if(props.currentLength == 1){
              history.push("/Patient" )
            }

          }
        }
      )
      // DeleteReport(props.uid, props.reportID);

    }else{
      console.log('no records');
    }

  };

  const deleteRequest = () => { // repurpose, but called when form is submitted to add the data to the blockchain 
    //href="/Patient"
    
    console.log(props); 
    console.log("deleteRequest"); 

    if(props.reportID != 0 ){
      console.log(props.reportID);
      console.log(props.uid);
      DeleteReport(props.uid, props.reportID);

      if(props.currentLength == 1){
        history.push("/Patient" )
      }
    }else{
      console.log('no records')
    }
  };
  
  return ( 

      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              
              fullWidth
              id="clinic"
              label="Clinic Name"
              name="clinic"  
              value= {report['clinicName']}
              disabled={report['clinicName'] == ""}  
            />
          </Grid> 
          <Grid item xs={12}>
            <TextField
              
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email" 
              value= {report['clinicEmail']}
              disabled={report['clinicEmail'] == ""}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth
              name="address"
              label="Address"   
              value= {report['clinicAddress']}
              disabled={report['clinicAddress'] == ""}  
            />
          </Grid> 
          <Grid item xs={12}>
            <TextField 
              fullWidth
              name="ReportType"
              label="Report Type"  
              value= {report['reportType']}
              disabled={report['reportType'] == ""}  
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              
              fullWidth
              name="ReportDetails"
              label="ReportDetails" 
              value= {report['ReportDetails']}
              disabled={report['ReportDetails'] == ""}  
            />
          </Grid>
          <Grid item xs={12} sm={6}>
              <Button 
                variant="contained" 
                endIcon={<CheckBoxIcon />}
                type="submit"
              >
                Approve
              </Button>
          </Grid>
          <Grid item xs={12} sm={6}> 
              <Button 
                variant="contained" 
                endIcon={<CancelIcon />}
                onClick={() => ( deleteRequest() ) }
              >
                Decline
              </Button>
          </Grid>
        </Grid>
        
      </Box>
    
  );
}

