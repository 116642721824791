import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';
 
 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import DashNavbar from "./DashNavbar";
import PdfDisplay from "./pdf";
// display individual records 
// replace nav link
// show pdf 
export default function ReportRecords(props) {

  console.log(props);
 
  const details = {
    "firstName": props.history.location.state? props.history.location.state.reportdetails.firstName:"",
    "lastName": props.history.location.state? props.history.location.state.reportdetails.lastName:"",
    "clinicName": props.history.location.state? props.history.location.state.reportdetails.clinicName:"",
    "docType": props.history.location.state? props.history.location.state.reportdetails.docType:"",
    "dataTime": props.history.location.state? props.history.location.state.reportdetails.dataTime:"",
    "HealthCard": props.history.location.state? props.history.location.state.reportdetails.HealthCard:"",
    "reportDetails": props.history.location.state? props.history.location.state.reportdetails.reportDetails:"",
    "reportID": props.history.location.state? props.history.location.state.reportdetails.reportID:"",
    "reportPath": props.history.location.state? props.history.location.state.reportdetails.reportPath:"",
    "reportType": props.history.location.state? props.history.location.state.reportdetails.reportType:""
  };

  return (
    <div>
      <CssBaseline /> 
      <DashNavbar title = "BlockSure" to = {props.history.location.state.to ? props.history.location.state.to :"/Patient"} />
      <Container component="main"  sx={{ mt: 3 }}>
        <CssBaseline />
        <Typography component="h1" variant="h3">
            Medical Report Details 
        </Typography>
        

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3} p>
            <Grid item xs={12} sm={6}>
              <br/>
              <Typography component="h1" variant="h5">
                Report Details 
              </Typography>
              <br/>
              
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="clinic"
                    label="Clinic Name"
                    name="clinic" 
                    value= {details['clinicName']}
                  />
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value= {details['firstName']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value= {details['lastName']}
                    />
                  </Grid> 
                
                <Grid item xs={12}>
                  <TextField
                    
                    fullWidth
                    name="reportPath"
                    label="report Path"  
                    value= {details['reportPath']}
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField 
                    fullWidth
                    name="ReportType"
                    label="Report Type" 
                    value= {details['reportType']} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    name="ReportDetails"
                    label="ReportDetails"
                    value= {details['reportDetails']} 
                  />
                </Grid>
              
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}> 
              <PdfDisplay reportdetails = {details}/>
            </Grid>
          </Grid>
          
        </Box>
      </Container>
    </div>
  );
}

